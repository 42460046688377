.trade-panel {
    background-color: #1e1e1e;
    color: #ffffff;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
    width: 100%;
    max-width: 600px;
    margin: auto;
}

.trade-table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 15px;
}

.trade-table td {
    padding: 8px 12px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.2);
}

.trade-table td:first-child {
    font-weight: bold;
    color: #f3f3f3;
}

.deal-status {
    font-weight: bold;
    padding: 6px 12px;
    border-radius: 5px;
    display: inline-block;
    text-transform: uppercase;
    font-size: 0.9em;
    background-color: #ff9800;
    color: #ffffff;
}

.label {
    background-color: #007bff;
    color: white;
    padding: 4px 8px;
    border-radius: 5px;
    font-size: 0.9em;
    margin-left: 5px;
}
