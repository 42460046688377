/* 📦 Основной контейнер формы */
.geo-photo-form {
  max-width: 600px;
  margin: 40px auto;
  background-color: #222;
  padding: 30px;
  border-radius: 12px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
}

.geo-photo-form h3 {
  text-align: center;
  color: #fff;
  margin-bottom: 20px;
  font-size: 24px;
}

.form-group {
  margin-bottom: 18px;
}

.form-group label {
  display: block;
  font-size: 16px;
  color: #ccc;
  margin-bottom: 6px;
}

.form-group input,
.form-group select,
.form-group textarea {
  width: 100%;
  padding: 10px;
  border-radius: 6px;
  border: 1px solid #444;
  background-color: #333;
  color: #fff;
  font-size: 16px;
  transition: border-color 0.3s ease;
}

.form-group input:focus,
.form-group select:focus,
.form-group textarea:focus {
  border-color: #28a745;
  outline: none;
}

.form-group textarea {
  height: 100px;
  resize: none;
}

/* ✅ Кнопка */
button {
  width: 100%;
  padding: 12px;
  font-size: 18px;
  background-color: #28a745;
  color: #fff;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  transition: background 0.3s;
}

button:hover {
  background-color: #218838;
}

/* ⚠️ Сообщение о результате */
.message {
  margin-top: 15px;
  text-align: center;
  font-weight: bold;
  font-size: 16px;
  color: #ffcc00;
}

/* 🖼️ Превью выбранного фото (если добавишь на фронте) */
.image-preview {
  margin-top: 10px;
  text-align: center;
}

.image-preview img {
  max-width: 100%;
  max-height: 250px;
  border-radius: 8px;
  box-shadow: 0 0 5px rgba(255, 255, 255, 0.2);
}
