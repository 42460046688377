/* ✅ frontend/src/styles/Map.css */

#map-container,
#map {
  height: 100vh;
  width: 100%;
  position: relative;
  background-color: #1e1e1e;
}

/* ✅ Стилизация всплывающих popup'ов */
.leaflet-popup-content {
  font-family: 'Segoe UI', sans-serif;
  font-size: 14px;
  color: #ddd;
  line-height: 1.4;
  padding-right: 4px;
}

.leaflet-popup-content strong {
  color: #fff;
}

.leaflet-popup-content button {
  margin: 5px 3px 3px 0;
  padding: 5px 10px;
  font-size: 13px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  background-color: #28a745;
  color: #fff;
}

.leaflet-popup-content button:hover {
  background-color: #218838;
}

.leaflet-popup-content textarea {
  width: 100%;
  height: 60px;
  padding: 6px;
  font-size: 13px;
  border-radius: 5px;
  border: 1px solid #666;
  background-color: #2e2e2e;
  color: #fff;
  resize: none;
  margin-top: 5px;
}

/* ✅ Стилизация превью фото в popup'е */
.leaflet-popup-content img {
  max-width: 100%;
  max-height: 120px;
  margin: 6px 0;
  border-radius: 6px;
  box-shadow: 0 0 5px rgba(255, 255, 255, 0.1);
}

/* ✅ Серая подпись автора под фото */
.photo-author {
  font-size: 11px;
  color: #aaa;
  margin-bottom: 8px;
  text-align: center;
}

/* ✅ Скрыть стрелочку и фон заголовка popup (по желанию) */
.leaflet-popup-tip,
.leaflet-popup-content-wrapper {
  background: #1e1e1e;
  color: #fff;
  border: 1px solid #333;
}

.adm3-loading-spinner {
  position: absolute;
  top: 10px;
  right: 20px;
  z-index: 1000;
  background: rgba(255, 255, 255, 0.95);
  padding: 8px 12px;
  border-radius: 8px;
  box-shadow: 0 2px 6px rgba(0,0,0,0.2);
  display: flex;
  align-items: center;
  gap: 10px;
  font-size: 14px;
}

.spinner {
  width: 16px;
  height: 16px;
  border: 2px solid #bbb;
  border-top-color: #2e8bff;
  border-radius: 50%;
  animation: spin 0.8s linear infinite;
}

@keyframes spin {
  to { transform: rotate(360deg); }
}


.leaflet-control-attribution {
  font-size: 0.6rem;
  opacity: 0.3;
  pointer-events: none;
}
